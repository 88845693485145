import Link from 'next/link';
// import Image from 'next/image';
import PropTypes from 'prop-types';
import Styles from './Card.module.scss';
import MyImage from 'components/MyImage';

export default function Footer({ data }) {
    return (
        <Link href={`/portfolio/${encodeURIComponent(data.slug)}`}>
            <a>
                <div className={`card ${Styles.card}`}>
                    <div className="card-image">
                        <figure className="image is-fullwidth">
                            {/* <Image
                                src={data.image_big}
                                alt={data.title}
                                layout="responsive"
                                width={data?.image_big_width ? data.image_big_width : 640}
                                height={data?.image_big_height ? data.image_big_height : 360}
                                loading="lazy"
                            /> */}

                            <MyImage
                                src={data.image_big}
                                alt={data.title}
                                layout="responsive"
                                width={data?.image_big_width ? data.image_big_width : 640}
                                height={data?.image_big_height ? data.image_big_height : 360}
                                loading="lazy"
                            />
                        </figure>
                    </div>
                    <div className="card-content">
                        <p className="title has-text-info is-size-4">{data.title}</p>
                        <p className="subtitle has-text-primary is-size-6 mb-3">{data.subtitle}</p>

                        <div className="tags are-small is-hidden-tablet">
                            {data.tags.map((tag, index) => (
                                <span key={`${tag}-${index}`} className="tag is-small">
                                    {tag}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </a>
        </Link>
    );
}

Footer.propTypes = {
    id: PropTypes.number,
    data: PropTypes.object,
    cover: PropTypes.bool,
};
